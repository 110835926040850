import axios from "axios";
import { api_url } from "../../../package.json";
// const API_URL = "http://localhost:3001/api/auth/";
// const API_URL = "https://staauthapi.ridgetopclimate.com/api/auth/"; 
//https://portal.ridgetopclimate.com/dashboard/default


class AuthService {
  login(username, password, status, message, rainfall_stationcodes) {
    return axios
      // .post(API_URL + "signin", {
      .post(`${api_url}/api/auth/signin`, {
        username,
        password,
        status,
        rainfall_stationcodes,
        message
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
          return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, status, message, rainfall_status, solar_exposure_status, min_temperature_status, max_teamperature_status) {
    return axios.post(`${api_url}/api/auth/signup`, {
      username,
      email,
      password,
      status,
      message,
      rainfall_status,
      solar_exposure_status,
      min_temperature_status,
      max_teamperature_status
    });
  }

  Edituser(username, email, password, message) {
    return axios.post(`${api_url}/api/auth/Edituser`, {
      username,
      email,
      password,
      message
    });
  }

  forgotpassword(email, message, status) {
    return axios.post(`${api_url}/api/auth/forgotpassword`, {
      email,
      message,
      status
    });
  }
  resetpassword(password, cpassword, username, message) {
    return axios.post(`${api_url}/api/auth/resetpassword`, {
      password,
      cpassword,
      username,
      message
    });
  }
  station(station, username) {
    return axios.post(`${api_url}/api/auth/station`, {
      station,
      username,

    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();