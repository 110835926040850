export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const ON_WEATHER_TYPE_CHANGE = 'ON_WEATHER_TYPE_CHANGE';
export const SET_IS_HIDDEN = 'SET_IS_HIDDEN';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_IS_COMPUTING = 'SET_IS_COMPUTING';
export const USER_DETAILS_UPDATE = 'USER_DETAILS_UPDATE';
export const WEATHER_CODE_UPDATE = 'WEATHER_CODE_UPDATE';
//export const REFRESH_TOKEN = "REFRESH_TOKEN";