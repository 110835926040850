import React from 'react';

const SignUp = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp'));
const Signin = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn'));
const Forgotpassword= React.lazy(() => import('./Demo/Authentication/Resetpass/Forgotpassword'));
const Resetpassword= React.lazy(() => import('./Demo/Authentication/Resetpass/Resetpassword'));


const route = [
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },    
    { path: '/auth/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/auth/forgotpassword', exact: true, name: 'Forgotpassword', component: Forgotpassword },
    { path: '/auth/resetpassword', exact: true, name: 'Resetpassword', component: Resetpassword },
];

export default route;